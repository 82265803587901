body {
  margin: 0;
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #161b21;
  font-size: 24px;
}

/* ========== Header ========== */

.head {
  margin-left: 5vb;
  margin-bottom: -50px;
  justify-content: space-between;
  padding: 1% 5%;
  line-height: 50%;
  /* width: 100vw; */
}

.head-name {
  display: flex;
}

.head h1 {
  font-size: 4vb;
}

.head h3 {
  font-size: 3vb;
  line-height: 100%;
  color: yellow;
}

.head h4 {
  font-size: 2vb;
  line-height: 100%;
  color: rgb(118, 197, 0);
}

/* ========== Sidebar ========== */

.sidebar-header {
  font-size: 2vb;
}

.menu1 {
  color: #165a72;
  font-size: 3vb;
  margin-bottom: 40px;
  margin-top: 20px;
}

.container {
  transition: transform 0.3s ease;
}

.translated {
  transform: translateX(-30vb);
}

/* ========== Pages ========== */

.page {
  color: #fff;
  margin-left: 5vb;
  margin-right: -30vb;
  padding-right: 5vb;
}

a:link {
  color: yellow;
}

a:visited {
  color: yellow;
}

.page h1 {
  color: white;
  margin: 50px auto 0px auto;
  font-size: 3vb;
}

.page h3 {
  color: rgb(118, 197, 0);
  font-size: 2.5vb;
}

.content {
  display: flex;
  align-items: center;
}

.content img {
  width: 40%;
  height: auto;
  margin-right: 10px;
}

.content ul {
  font-size: 2vb;
}

.page p {
  font-size: 1.8vb;
  text-align: justify;
  text-justify: inter-word;
}

.page h5 {
  font-size: 1.5vb;
}

/* ========== People ========== */

.people {
  margin-right: 20px;
}

.people img {
  width: 20vb;
  height: auto;
  margin-right: 10px;
}

/* ========== Projects ========== */

.projects h3 {
  font-size: 2vb;
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: -10px;
}

.projects p {
  font-size: 1.5vb;
  text-align: justify;
  text-justify: inter-word;
}

.navbar {
  display: flex;
  align-items: center;
}

.navbar a {
  text-decoration: none
}

.navbar h3 {
  margin: 5vb 2vb -2vb;
  padding: 1vb 4vb;
  font-size: 2vb;
  color: yellow;
  border-radius: 20px;
  background-color: green;
}